import React, { useState, useCallback, useEffect } from 'react';
import {
  LegacyStack, Tag,
  Combobox, Listbox, Text, AutoSelection,
} from '@shopify/polaris';
import { getAllHorseVariantsTagOptions } from '../../../api_utils/requests'

const emptyArray: string[] = [];
function TagCheckList({
  selectedOptions = emptyArray,
  onAdd,
  onChange,
  disabled = false,
  createNew = true,
}: {
  readonly selectedOptions: string[],
  // Required unless disabled
  readonly onAdd?: (tag: string) => void,
  readonly onChange?: (tags: string[]) => void,
  readonly disabled?: boolean,
  readonly createNew?: boolean,
}) {
  const [inputValue, setInputValue] = useState('');
  // The full list of options
  const [initialOptions, setInitialOptions] = useState<string[]>([]);
  // The tag choices based on what they've typed (inputValue)
  const [options, setOptions] = useState<string[]>([]);
  const [suggestion, setSuggestion] = useState('');

  const handleActiveOptionChange = useCallback(
    (activeOption: string) => {
      const activeOptionIsAction = activeOption === inputValue;

      if (!activeOptionIsAction && !selectedOptions.includes(activeOption)) {
        setSuggestion(activeOption);
      } else {
        setSuggestion('');
      }
    },
    [inputValue, selectedOptions],
  );

  useEffect(() => {
    getAllHorseVariantsTagOptions(inputValue).then((newOptions) => {
      setInitialOptions(newOptions);
      setOptions(newOptions);
    });
  }, [inputValue]);

  const updateText = useCallback((value: string) => {
    setInputValue(value);

    if (!value) {
      setOptions(initialOptions);
      return;
    }

    const filterRegex = new RegExp(value, 'i');
    const resultOptions = initialOptions.filter((option) => option.match(filterRegex));
    setOptions(resultOptions);
  }, [initialOptions]);

  const updateSelection = useCallback((selected: string) => {
    let newList: string[] = [];
    // If it's selected, delete it
    if (selectedOptions.includes(selected)) {
      newList = selectedOptions.filter((option) => option !== selected);
      onChange(newList);
    } else {
      // If it isn't a choice AND we create new ones, add it
      if (!initialOptions.includes((selected)) && createNew) {
        onAdd(selected);
      }
      newList = [...selectedOptions, selected];
      onChange(newList);
    }
  }, [options, selectedOptions]);

  const formatOptionText = useCallback(
    (option: string) => {
      const trimValue = inputValue.trim().toLocaleLowerCase();
      const matchIndex = option.toLocaleLowerCase().indexOf(trimValue);

      if (!inputValue || matchIndex === -1) return option;

      const start = option.slice(0, matchIndex);
      const highlight = option.slice(matchIndex, matchIndex + trimValue.length);
      const end = option.slice(matchIndex + trimValue.length, option.length);

      return (
        <p>
          {start}
          <Text as="span" fontWeight="bold">
            {highlight}
          </Text>
          {end}
        </p>
      );
    },
    [inputValue],
  );

  const verticalContentMarkup =
    selectedOptions.length > 0 ? (
      <LegacyStack alignment="center" spacing="extraTight">
        {selectedOptions.map((tag) => (
          <Tag key={`option-${tag}`} onRemove={disabled ? undefined : () => { updateSelection(tag); }}>
            {tag}
          </Tag>
        ))}
      </LegacyStack>
    ) : null;

  const optionMarkup =
    options.length > 0 ? options.map((option) => {
          return (
            <Listbox.Option
              accessibilityLabel={option}
              key={option}
              selected={selectedOptions.includes(option)}
              value={option}
            >
              <Listbox.TextOption selected={selectedOptions.includes(option)}>
                {formatOptionText(option)}
              </Listbox.TextOption>
            </Listbox.Option>
          );
        }): null;

  const noResults = inputValue && !options.includes(inputValue);

  const actionMarkup = noResults && createNew && !selectedOptions.includes(inputValue) ? (
    <Listbox.Action value={inputValue}>{`Add "${inputValue}"`}</Listbox.Action>
  ) : null;

  const listboxMarkup =
    optionMarkup || actionMarkup ? (
      <Listbox
        autoSelection={AutoSelection.None}
        onActiveOptionChange={handleActiveOptionChange}
        onSelect={updateSelection}
      >
        {actionMarkup}
        {optionMarkup}
      </Listbox>
    ) : noResults && !createNew ? (
      <Listbox.Header>No tags found</Listbox.Header>
    ) : null;

  return (
    disabled ? verticalContentMarkup : <Combobox
      activator={
        <Combobox.TextField
          autoComplete='off'
          disabled={disabled}
          label="Search tags"
          labelHidden
          onChange={updateText}
          placeholder="Search tags"
          suggestion={suggestion}
          value={inputValue}
          verticalContent={verticalContentMarkup}
        />
      }
      allowMultiple
    >
      <div id="force-greater-z">
        {listboxMarkup}
      </div>
    </Combobox>
  );
}

export default TagCheckList;
